import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Pagination from "react-js-pagination";
import "../../../components/Style/Table.css"
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
} from "@material-ui/core";
import GradePopUp from "./GradePopUp";
import PointPopUp from "./PointPopUp";
import MileagePopUp from "./MileagePopUp";

export default function MemTable(props) {
  const filterKeyword = props.filterKeyword || '';
  const row = props.row;
  const searchTargets = props.searchTargets;
  const totalData = props.totalData;
  const [dataCnt, setDataCnt] = useState(0);
  // console.log(totalData)

  // pagination
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState([]);
  const postPerPage: number=10;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  //dialog
  const [gradeOpen, setGradeOpen] = useState(false);
  const [pointOpen, setPointOpen] = useState(false);
  const [mileageOpen, setMileageOpen] = useState(false);
  const [selectedMemberSeq, setSelectedMemberSeq] = useState(null);
  // let memberSeq;

  const gradeClickOpen = (item) => {
    // console.log(item.member_seq);
    // memberSeq = item.member_seq
    setSelectedMemberSeq(item.member_seq);
    setGradeOpen(true);
  };
  const gradeClose = () => {
    setGradeOpen(false);
    setSelectedMemberSeq(null);
  };

  const pointClickOpen = (item) => {
    setPointOpen(true);
    // memberSeq = item.member_seq
    setSelectedMemberSeq(item.member_seq);
  };
  const pointClose = () => {
    setPointOpen(false);
    setSelectedMemberSeq(null);
  };

  const mileageClickOpen = (item) => {
    setMileageOpen(true);
    // memberSeq = item.member_seq
    setSelectedMemberSeq(item.member_seq);
  };
  const mileageClose = () => {
    setMileageOpen(false);
    setSelectedMemberSeq(null);
  }

  //search
  const searchItem = (filterKeyword) => {
    if (filterKeyword !== ''){
      const filteredData = totalData.filter((item) => {
        return searchTargets.some((target) => {
          if (item[target] && typeof item[target] === 'string') {
            return item[target].toLowerCase().includes(filterKeyword.toLowerCase());
          }
        })
        console.log(filteredData)
      });
      // pagination filter data slice
      setCurrentPost(filteredData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setDataCnt(filteredData.length);
    } else {
      // pagination total data slice
      setCurrentPost(totalData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setDataCnt(totalData.length);
    }
  }

  useEffect(() => {
    searchItem(filterKeyword)
  }, [totalData, page, filterKeyword])

  return (
    <div>
      <TableContainer>
          <Table className="mb-0">
            <TableHead>
              <TableRow>
                {row && row.map(headerItem => (
                  <TableCell style={{ textAlign: 'center'}}>{headerItem}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPost && currentPost.map(item => (
                <TableRow key={item.num}>
                  <TableCell style={{ textAlign: 'center'}}>{item.member_seq}</TableCell>
                  <TableCell style={{ textAlign: 'center'}}>{item.name}</TableCell>
                  <TableCell style={{cursor:"pointer", textAlign:'center'}} onClick={() => gradeClickOpen(item)}>
                    {item.grade_name}<br />{item.end_date}
                  </TableCell>
                  <TableCell style={{cursor:"pointer", textAlign:'center'}} onClick={() => pointClickOpen(item)}>{item.point_amount}</TableCell>
                  <TableCell style={{cursor:"pointer", textAlign:'center'}} onClick={() => mileageClickOpen(item)}>{item.mileage_amount}</TableCell>
                  <TableCell style={{ textAlign: 'center'}}>{item.email}</TableCell>
                  <TableCell style={{ textAlign: 'center'}}>{item.org_name}<br />{item.org_type}</TableCell>
                  <TableCell style={{ textAlign: 'center'}}>통합 프로젝트: {item.total_project_cnt}<br />특허 프로젝트: {item.patents_project_cnt}</TableCell>
                  <TableCell style={{ textAlign: 'center'}}>{item.sub_data}<br />{item.last_modified_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <RealTableFooter>
            <Pagination
              activePage={page}
              itemsCountPerPage={postPerPage}
              totalItemsCount={dataCnt}
              pageRangeDisplayed={5}
              prevPageText={"<"}
              nextPageText={">"}
              onChange={handlePageChange}
            />
          </RealTableFooter>
      </TableContainer>
      <GradePopUp open={gradeOpen} close={gradeClose} member_seq={selectedMemberSeq}/>
      <PointPopUp open={pointOpen} close={pointClose} member_seq={selectedMemberSeq} />
      <MileagePopUp open={mileageOpen} close={mileageClose} member_seq={selectedMemberSeq} />
    </div>
  )
}

const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`