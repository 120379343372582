import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import CouponListTable from "./components/CouponListTable";
import {
  Grid,
  Paper,
  InputBase,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import { Search as SearchIcon } from "@material-ui/icons";
import Widget from "../../components/Widget";
import styled from "styled-components";

function Plan(){
  //pagination 변수들: 받아오는 리스트, 나열할 리스트, 페이지
  const [tableList, setTableList] = useState([]);
  const row = ["NO","코드명","상품명","사용 가능 인원","가격","시작일","종료일"]
  const member_seq = -1
  const [planList, setPlanList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const searchTargets = ["grant_code", "note"];

  const getValue = (e) => {
    setSearchInput(e.target.value)
  }

  // 쿠폰 목록 api
  useEffect(() => {
    axios.get('/api/member/planCouponList?member_seq='+member_seq)
      .then((response) => {
        setTableList([...response.data.data.coupon].reverse())
      })
  },[])
  return(
    <>
      <PageTitle title="플랜 관리" />
      <Grid>
        <SearchPaper component="form" sx={{ display: 'flex', alignItems: 'center', width: '400' }}>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" ><SearchIcon /></IconButton>
          <SearchInput
            placeholder="Search"
            inputProps={{'aria-label': 'Search'}}
            onChange = {getValue}
          />
        </SearchPaper>
      </Grid>
      <Grid item xs={12} >
        <Widget>
          <CouponListTable
            row={row}
            filterKeyword={searchInput}
            totalData={tableList}
            searchTargets={searchTargets}
          />
        </Widget>
      </Grid>
    </>
  )
}
export default Plan;

const SearchInput = styled (InputBase)`
    display: inline-flex;
    margin-top: 10px;
    width: 80%;
`
const SearchPaper = styled(Paper)`
    margin-bottom: 10px;
    width: 20%;
`
