// 상품 정보 페이지
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  InputBase,
  IconButton,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import styled from 'styled-components';
import axios from 'axios';
import ProductTable from "./components/ProductTable";

function Product(){
  //pagination 변수들: 받아오는 리스트, 나열할 리스트, 페이지
  const [tableList, setTableList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const row = ["NO","등급 이름", "가격","통합 프로젝트 제한","특허 프로젝트 제한","맞춤형 리포트 제한","사용 가능 인원"]
  const searchTargets = ["grade_name"];

  // search
  const getValue = (e) => {
    setSearchInput(e.target.value)
  }

  // api
  useEffect(() => {
    axios.get("/api/product/productList")
      .then((response) => {
          setTableList([...response.data.data].reverse())
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  return(
    <div>
      <PageTitle title="상품 정보" />
      <Grid>
        <SearchPaper component="form" sx={{ display: 'flex', alignItems: 'center', width: '400' }}>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" ><SearchIcon /></IconButton>
          <SearchInput
            placeholder="Search"
            inputProps={{'aria-label': 'Search'}}
            onChange = {getValue}
          />
        </SearchPaper>
      </Grid>
      <Grid item xs={12} >
        <Widget>
          <ProductTable row={row} filterKeyword={searchInput} totalData={tableList} searchTargets={searchTargets}/>
        </Widget>
      </Grid>
    </div>
  );
}
export default Product;

const SearchInput = styled (InputBase)`
    display: inline-flex;
    margin-top: 10px;
    width: 80%;
`
const SearchPaper = styled(Paper)`
    margin-bottom: 10px;
    width: 20%;
`