//회원 정보 페이지
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  InputBase,
  IconButton,
  Box,
  Tab,
} from "@material-ui/core";
import {TabList, TabPanel, TabContext} from '@material-ui/lab';
import { Search as SearchIcon } from "@material-ui/icons";
import Widget from "../../components/Widget";
import MemTable from "./components/MemTable";
import PageTitle from "../../components/PageTitle";
import PlanMemTable from "./components/PlanMemTable";
import styled from 'styled-components';
import axios from 'axios';

// 데이터 정비
function combineData(arr, index1, index2, index3, index4, index5, index6, index7){
  arr.forEach((item) => {
    const value1 = item[index1];
    const value2 = item[index2];
    const value3 = item[index3];
    const value4 = item[index4];
    const value5 = item[index5];
    const value6 = item[index6];
    const value7 = item[index7];
    item[index1] = value1.split('T')[0];
    item[index2] = value2.split('T')[0];
    if(value3 == null){
      item[index3] = 0;
    }
    if(value4 == null){
      item[index4] = 0;
    }
    if(value6 == null){
      item[index6] = 0;
    }
    if(value7 == null){
      item[index6] = 0;
    }
    if(value5 == null){
      item[index5] = "기본";
    }
  })
  // console.log(arr)
  return arr;
}

export default function Member(){
  //pagination 변수들: 받아오는 리스트, 나열할 리스트, 페이지
  const [value, setValue] = useState("1");
  const [tableList, setTableList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const row = ["NO","이름","등급","포인트","마일리지","메일","소속","프로젝트 수","가입일"]
  const searchTargets = ["name", "email", "org_name", "org_type"];

  // search
  const getValue = (e) => {
    setSearchInput(e.target.value)
  }

  const tabChange = (event, newValue) => {
    setValue(newValue);
  };

  //전체 회원 api
  useEffect(() => {
    axios.get("/api/member/list")
      .then((response) => {
        setTableList(combineData(
          [...response.data.data.member],
          "sub_data",
          "last_modified_date",
          "total_project_cnt",
          "patents_project_cnt",
          "grade_name",
          "point_amount",
          "mileage_amount").reverse())
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  // 플랜 회원 api
  useEffect(() => {
    axios.get("/api/member/planMemList")
      .then((response) => {
        setPlanList(response.data.data)
      })
      .catch(function(error){
        console.log(error)
      });
  }, [])

  return(
    <div>
      <PageTitle title="회원 정보" />
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', '& .PrivateTabIndicator-root-62.PrivateTabIndicator-colorSecondary-64.MuiTabs-indicator': { backgroundColor:'#6BB9FF' } }} style={{paddingLeft: '24px'}}>
            <TabList onChange={tabChange}>
              <Tab label="전체 회원" value="1" />
              <Tab label="플랜 회원" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" label="전체 회원">
            <Grid>
              <SearchPaper component="form" sx={{ display: 'flex', alignItems: 'center', width: '400' }}>
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" ><SearchIcon /></IconButton>
                <SearchInput
                  placeholder="Search"
                  inputProps={{'aria-label': 'Search'}}
                  onChange = {getValue}
                />
              </SearchPaper>
            </Grid>
            <Grid item xs={12} >
              <Widget>
                <MemTable
                  row={row}
                  filterKeyword={searchInput}
                  totalData={tableList}
                  searchTargets={searchTargets}
                />
              </Widget>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid>
              <SearchPaper component="form" sx={{ display: 'flex', alignItems: 'center', width: '400' }}>
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" ><SearchIcon /></IconButton>
                <SearchInput
                  placeholder="Search"
                  inputProps={{'aria-label': 'Search'}}
                  onChange = {getValue}
                />
              </SearchPaper>
            </Grid>
            <Grid item xs={12} >
              <Widget>
                <PlanMemTable
                  filterKeyword={searchInput}
                  totalData={planList}
                  searchTargets={searchTargets}
                />
              </Widget>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

const SearchInput = styled (InputBase)`
    display: inline-flex;
    margin-top: 10px;
    width: 80%;
`
const SearchPaper = styled(Paper)`
    margin-bottom: 10px;
    width: 20%;
`
