import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Box,
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  Typography,

} from "@material-ui/core";
import Pagination from "react-js-pagination";
import styled from 'styled-components';
import "../../../components/Style/Table.css"
import { makeStyles } from "@material-ui/styles";

function changePointData(arr, index1, index2){
  arr.forEach((item) => {
    const value1 = item[index1];
    const value2 = item[index2];
    item[index1] = value1.split('T')[0];
    if(value2 <0){
      item.system = "사용";
    }else {item.system = "적립";}
  })
  // console.log(arr);
  return arr;
}

export default function PointPopUp(props){
  const { open, close, member_seq } = props;
  const [point_value, setPoint_value] = useState('');
  const [pointInfo, setPointInfo] = useState('');
  const [comment, setComment] = useState('');
  const [page, setPage] = useState(1);
  const postPerPage: number=5;
  const classes = useStyles();
  const getPoint = (e) => {
    setPoint_value(e.target.value)
    // console.log(point_value)
  }
  const getComment = (e) => {
    setComment(e.target.value)
    // console.log("comment:"+comment)
  }
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  // 포인트 내역
  useEffect(() => {
    axios.get("/api/member/pointInfo?member_seq=" + member_seq)
      .then((response)=>{
        setPointInfo(changePointData([...response.data.data.point], "accumulation_date", "accumulation_amount").reverse());
      })
      .catch((error) => {
        console.error('Error gradePopUp checkSubscription axios request:', error);
      })
  }, [member_seq]);

  // 포인트 바꾸기
  const changePoint = () => {
    axios.get("/api/member/changePoint?member_seq=" + member_seq + "&point_value=" + point_value + "&comment=" + comment)
      .then(()=>{
      })
      .catch((error) => {
        console.error('Error PointPopUp gradeDetail axios request:', error);
      })
  }
  return(
    <div className={open ? "gradeClickOpen modal" : "modal"}>
      {open ? (
        <Dialog
          open={open}
          onClose={close}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              boxShadow: "none",
            },
          }}>
          <DialogTitle style={{marginTop: 20}}><Typography variant="h5" align="center">포인트 내역</Typography></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ justifyContent: 'center', display: 'flex'}} >
                <Table style={{width: 900}}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center'}}>누적 포인트</TableCell>
                      <TableCell style={{ textAlign: 'center'}}>분류(적립/사용)</TableCell>
                      <TableCell style={{ textAlign: 'center'}}>일자</TableCell>
                      <TableCell style={{ textAlign: 'center'}}>포인트</TableCell>
                      <TableCell style={{ textAlign: 'center'}}>사유</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(pointInfo) && pointInfo.map(item => (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center'}}>{item.cumulative_point}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.system}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.accumulation_date}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.accumulation_amount}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.accumulation_reason}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <RealTableFooter>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={postPerPage}
                  totalItemsCount={pointInfo.length}
                  pageRangeDisplayed={5}
                  prevPageText={"<"}
                  nextPageText={">"}
                  onChange={handlePageChange}
                />
              </RealTableFooter>
            </DialogContentText>
          </DialogContent>
          <DialogTitle><Typography variant="h5" align="center">포인트 수정</Typography></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box
                component="form"
                sx={{ justifyContent: 'center', display: 'flex',
                  '& > :not(style)': { m: 1, width: '35ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  Props={{
                    style: {
                      margin: 10
                    }
                  }}
                  id="standard-basic"
                  label="변경할 포인트 값"
                  type="number"
                  onChange={getPoint}/>
              </Box>
              <Box
                component="form"
                sx={{ justifyContent: 'center', display: 'flex',
                  '& > :not(style)': { m: 1, width: '35ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  Props={{
                    style: {
                      margin: 10
                    }
                  }}
                  id="standard-basic"
                  label="사유"
                  onChange={getComment}/>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: classes.MuiDialogActions }}  style={{ marginBottom: 20}}>
            <Button style={{backgroundColor:"#3085D6", color: "white", fontWeight: 'medium', boxShadow: 'none'}} variant="contained" onClick={changePoint}>확인</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  )
}

const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`
const useStyles = makeStyles(theme => ({
  MuiDialogActions: {
    justifyContent: 'center'
  }
}))