import React, { useState, useEffect, Component } from "react";
import  Editor  from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Divider, Table, TableRow, TableCell } from "@material-ui/core";
// import { makeStyles } from "@material-ui/styles";
// import styled from "styled-components";
import "../components/style/Register.css"
import { DropzoneArea } from "material-ui-dropzone";
import { useLocation } from "react-router-dom";

export default function Register(props){
  const location = useLocation();
  // files 저장
  const [files, setFiles] = useState();
  const {detailData, seq} = props.location.state;
  console.log(detailData)

  const editorPlugin = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'highlight',
        'underline',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        'link',
        '|',
        'alignment',
        'fontFamily',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
        '-',
        'style',
        'textPartLanguage',
        'undo',
        'redo',
        '|',
        'imageUpload',
        'mediaEmbed',
        'blockQuote',
        'insertTable',
        '|',
        'code',
        'sourceEditing',
        'pageBreak',
        'selectAll',
        'findAndReplace'
      ],
      shouldNotGroupWhenFull: true
    },
    language: 'ko',
    image: {
      toolbar: [
        'imageTextAlternative',
        'toggleImageCaption',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    }
  }


  return(
    <div>
      <div className="w1200">
        <h2>고객센터 글 등록</h2>
        <div className="whiteBox">
          <div className="group">
            <h3 style={{ minWidth: 130 }}>제목</h3>
            <input className="input_style" style={{ width: "calc(100% - 20px)" }} value={detailData[0].title} />
          </div>
          <Divider style={{ marginBottom: 20 }} />
          <div style={{ marginBottom: 25 }}>
            <div style={{ display: "flex" }}>
              <div className="group">
                <lable style={{ minWidth: 130, fontSize: '15px' }}>게시판 선택</lable>
                <select className="input_style" style={{ width: "calc(75%)" }} defaultValue={detailData[0].kind_code}>
                  <option value={undefined}></option>
                  <option value={2}>공지사항</option>
                  <option value={3}>FAQ</option>
                </select>
              </div>
              <div className="group ml-50">
                <lable style={{ minWidth: 130, fontSize: "15px" }}>공개 여부</lable>
                <select className="input_style" style={{ width: "calc(75%)" }} defaultValue={detailData[0].is_published}>
                  <option value={undefined}></option>
                  <option value={0}>비공개</option>
                  <option value={1}>공개</option>
                </select>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="group">
                <label style={{ minWidth: 130, fontSize: "15px" }}>카테고리</label>
                <input className="input_style" style={{ width: "calc(100% - 20px)" }} value={detailData[0].category}/>
              </div>
            </div>
          </div>
          <h3>내용</h3>
          <Divider style={{ marginBottom: 20 }} />
          <div style={{ marginBottom: 25 }}>
            <div style={{ display: "flex" }}>
              <div className="group">
                <label style={{ minWidth: 130, fontSize: "15px" }}>본문</label>
                <CKEditor className="ck-editor ck-editor__editable" editor={Editor} config={editorPlugin} data={detailData[0].content} />
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="group">
              <label style={{ minWidth: 130, fontSize: "15px" }}>파일 첨부</label>
              <section style={{ width: "calc(100% - 20px)" }}>
                <DropzoneArea
                  acceptedFiles={['.png','.jpg','.doc*','.pdf','.hwp','.txt','.xls*','.jpeg','ppt*']}
                  dropzoneText={"Drag & Drop or Choose file to upload"}
                  filesLimit={10}
                />
                <label style={{fontSize: "14px", color: "#ACB5CF"}}>* 첨부파일을 업로드 할 수 있습니다. 업로드가 완료될 때까지 화면을 닫지 마세요.</label>
                <br />
                <label style={{fontSize: "14px", color: "#ACB5CF"}}>.png .jpg .jpeg .pdf .xls* .doc* .hwp .txt .ppt* 의 확장자만 가능합니다.</label>
              </section>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="group">
              <div style={{ minWidth: 130 }}></div>
              <Table>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>파일명</TableCell>
                  <TableCell>업로드</TableCell>
                  <TableCell>삭제</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
