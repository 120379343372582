import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableFooter, TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Pagination from "react-js-pagination";

export default function SharePopUp(props){
  const { open, close, project_id } = props;
  const classes = useStyles();
  const [shareListInfo, setShareListInfo] = useState();
  const [page, setPage] = useState(1);
  const postPerPage: number=5;
  const emailValue = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/g;
  const [isItEmail, setIsItEmail] = useState(false);
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const [email, setEmail] = useState();
  const getEmail = (e) => {
    if(emailValue.test(e.target.value)){
      setEmail(e.target.value)
      setIsItEmail(true)
      console.log("이메일 마즘")
    } else {
      setIsItEmail(false)
      console.log("이메일 아님")
    }
    // setEmail(e.target.value);
  }
  
  //공유
  const updateShare = () => {
    axios.get("/api/projects/shareProject?email=" + email + '&project_id=' + project_id )
      .then((response)=>{
        if(response.data.code = 10){
          console.log(response.data.message)
        }
      })
      .catch((error) => {
        console.error('Error updateShareID axios request:', error);
      })
  }

  // 공유된 사람들 리스트
  useEffect(() => {
    // console.log(project_id);
    axios.get("/api/projects/shareProjectList?project_id=" + project_id)
      .then((response)=>{
        setShareListInfo(response.data.data);
        // console.log(response.data.data);
      })
      .catch((error) => {
        console.error('Error SharePopUp shareList axios request:', error);
      })
  }, [project_id]);

  return(
    <div className={open ? "gradeClickOpen modal" : "modal"}>
      {open ? (
        <Dialog
          open={open}
          onClose={close}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              boxShadow: "none",
            },
          }}
        >
          <DialogTitle style={{marginTop: 20}}><Typography variant="h5" align="center">프로젝트 공유</Typography></DialogTitle>
          <Box
            component="form"
            sx={{ justifyContent: 'center', display: 'flex',
              '& > :not(style)': { m: 1, width: '70ch' },
            }}
            noValidate
            autoComplete="off">
            <TextField
              Props={{
                style: {
                  margin: 10,
                }
              }}
              id="standard-basic"
              label="이메일 입력"
              onChange={getEmail}
            />
            <Button
              variant="contained"
              disabled={!isItEmail}
              style={{
                backgroundColor: isItEmail ? "#3085D6" : "#CED4D4",
                color: "white",
                fontWeight: 'medium',
                boxShadow: 'none',
                width: 30,
                height: 40,
                marginLeft: 20,
                marginTop: 10,
              }}
              onClick={updateShare}
            >추가</Button>
          </Box>
          {!isItEmail &&
            <Typography style={{textAlign:'center', color:'#FF3852'}}>*이메일의 형태로 작성해주세요.</Typography>
          }
          <DialogTitle style={{marginTop: 20}}><Typography variant="h5" align="center">공유 회원 목록</Typography></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ justifyContent: 'center', display: 'flex'}} >
                <Table style={{width: 900}}>
                  <TableHead>
                    <TableCell style={{ textAlign: 'center'}}>NO</TableCell>
                    <TableCell style={{ textAlign: 'center'}}>이름</TableCell>
                    <TableCell style={{ textAlign: 'center'}}>이메일</TableCell>
                    <TableCell style={{ textAlign: 'center'}}>소속기관</TableCell>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(shareListInfo) && shareListInfo.map((item, index) => (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center'}}>{index + 1}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.name}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.email}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.org_name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <RealTableFooter>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={postPerPage}
                  totalItemsCount={shareListInfo && shareListInfo.length}
                  pageRangeDisplayed={5}
                  prevPageText={"<"}
                  nextPageText={">"}
                  onChange={handlePageChange}
                />
              </RealTableFooter>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  MuiDialogActions: {
    justifyContent: 'center'
  }
}))

const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`