import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../components/Style/Table.css";
import styled from 'styled-components';
import Pagination from "react-js-pagination";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Box,
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  Typography,

} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

function changeMileageData(array, index1, index2){
  array.forEach((item) => {
    const value1 = item[index1];
    const value2 = item[index2];
    item[index1] = value1.split('T')[0];
    if(value2 <0){
      item.system = "사용";
    }else {item.system = "적립";}
  })
  // console.log(array);
  return array;
}
export default function MileagePopUp(props){
  const { open, close, member_seq } = props;
  const [mileage_value, setMileage_value] = useState('');
  const [mileageInfo, setMileageInfo] = useState('');
  const [comment, setComment] = useState('');
  const [page, setPage] = useState(1);
  const postPerPage: number=5;
  const classes = useStyles();
  const getMileage = (e) => {
    // setMileage_value(e.target.value.replace(/\D/g,""))
    setMileage_value(e.target.value)
    // console.log(mileage_value)
  }
  const getComment = (e) => {
    setComment(e.target.value)
  }
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  // 마일리지 내역
  useEffect(() => {
    axios.get("/api/member/mileageInfo?member_seq=" + member_seq)
      .then((response)=>{
        setMileageInfo(changeMileageData([...response.data.data.mileage], "accumulation_date", "accumulation_amount").reverse());
        // console.log(response.data.data.mileage);
      })
      .catch((error) => {
        console.error('Error MileagePopUp checkSubscription axios request:', error);
      })
  }, [member_seq]);

  // 마일리지 수정
  const changeMileage = () => {
    axios.get("/api/member/changeMileage?member_seq=" + member_seq + "&mileage_value=" + mileage_value + "&comment=" + comment)
      .then(()=>{
      })
      .catch((error) => {
        console.error('Error gradePopUp gradeDetail axios request:', error);
      })
  }

  return(
    <div className={open ? "gradeClickOpen modal" : "modal"}>
      {open ? (
        <Dialog
          open={open}
          onClose={close}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              boxShadow: "none",
            },
          }}>
          <DialogTitle style={{marginTop: 20}}><Typography variant="h5" align="center">마일리지 내역</Typography></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ justifyContent: 'center', display: 'flex'}} >
                <Table style={{width: 900}}>
                  <TableHead>
                    <TableCell style={{ textAlign: 'center'}}>누적 마일리지</TableCell>
                    <TableCell style={{ textAlign: 'center'}}>분류(적립/사용)</TableCell>
                    <TableCell style={{ textAlign: 'center'}}>일자</TableCell>
                    <TableCell style={{ textAlign: 'center'}}>마일리지</TableCell>
                    <TableCell style={{ textAlign: 'center'}}>사유</TableCell>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(mileageInfo) && mileageInfo.map(item => (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center'}}>{item.cumulative_mileage}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.system}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.accumulation_date}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.accumulation_amount}</TableCell>
                        <TableCell style={{ textAlign: 'center'}}>{item.accumulation_reason}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <RealTableFooter>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={postPerPage}
                  totalItemsCount={mileageInfo.length}
                  pageRangeDisplayed={5}
                  prevPageText={"<"}
                  nextPageText={">"}
                  onChange={handlePageChange}
                />
              </RealTableFooter>
            </DialogContentText>
          </DialogContent>
          <DialogTitle><Typography variant="h5" align="center">마일리지 수정</Typography></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box
                component="form"
                sx={{ justifyContent: 'center', display: 'flex',
                  '& > :not(style)': { m: 1, width: '35ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  Props={{
                    style: {
                      margin: 10
                    }
                  }}
                  id="standard-basic"
                  label="변경할 마일리지 값"
                  type="number"
                  onChange={getMileage} />
              </Box>
              <Box
                component="form"
                sx={{ justifyContent: 'center', display: 'flex',
                  '& > :not(style)': { m: 1, width: '35ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  Props={{
                    style: {
                      margin: 10
                    }
                  }}
                  id="standard-basic"
                  label="사유"
                  onChange={getComment}/>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: classes.MuiDialogActions }} style={{ marginBottom: 20}}>
            <Button style={{backgroundColor:"#3085D6", color: "white", fontWeight: 'medium', boxShadow: 'none'}} variant="contained" onClick={changeMileage}>확인</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  MuiDialogActions: {
    justifyContent: 'center'
  }
}))
const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`