import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../components/Style/Table.css";
import styled from 'styled-components';
import "./style/DirectCenterTable.css";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

function changeData(array, index1){
  array.forEach((item) => {
    const value1 = item[index1];
    item[index1] = "C:\\TD-groupware\\file\\광경화 레진_20240110.xlsx"
  })
  console.log(array);
  return array;
}

export default function DirectDetailPopUp(props){
  const { open, close, seq } = props;
  const [directDetail, setDirectDetail] = useState();

  // 상세 문의 내용
  useEffect(() => {
    axios.get("/api/memberCenter/directDetail?seq=" + seq)
      .then((response)=>{
        setDirectDetail(changeData([...response.data.data], "file_path").reverse());
      })
      .catch((error) => {
        console.error('Error MileagePopUp checkSubscription axios request:', error);
      })
  }, [seq]);

  return (
    <div style={{display:"flex", alignItems:"center"}} className={open ? "gradeClickOpen modal" : "modal"}>
      {open ? (
        <Dialog
          open={open}
          onClose={close}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              boxShadow: "none",
              padding: 30,
            },
          }}>
          <div className="whiteBox">
            <DialogContent>
              {Array.isArray(directDetail) && directDetail.map(item => (
                <DialogContentText>
                  <h2 style={{margin: 0, color: "black"}}>1:1 문의 내용 상세</h2>
                  <Divider style={{marginTop: 3, marginBottom: 20}} />
                  <h3 style={{margin: 0}}>기본 정보</h3>
                  <div class="group">
                    <label style={{ minWidth: 130 }}>제목 </label>
                    <Paper elevation={0} variant="outlined" style={{
                    padding: 10,
                    width: "calc(100% - 20px)",
                  }}>{item.title}</Paper>
                  </div>
                  <h3 style={{margin: 0}}>담당자 정보</h3>
                  <div style={{ display: "flex" }}>
                    <div className="group">
                      <label style={{ minWidth: 130 }}>이름</label>
                      <Paper elevation={0} variant="outlined" style={{ padding: 10, width: "calc(100% - 40px)" }}>{item.member_name}</Paper>
                    </div>
                    <div className="group ml-50">
                      <label style={{ minWidth: 130 }}>기관(회사)명</label>
                      <Paper elevation={0} variant="outlined" style={{ padding: 10, width: "calc(100% - 40px)" }}>{item.org_name}</Paper>
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <div className="group">
                      <label style={{ minWidth: 130 }}>이메일</label>
                      <Paper elevation={0} variant="outlined" style={{ padding: 10, width: "calc(100% - 40px)" }}>{item.member_email}</Paper>
                    </div>
                    <div className="group ml-50">
                      <label style={{ minWidth: 130 }}>연락처(전화번호)</label>
                      <Paper elevation={0} variant="outlined" style={{ padding: 10, width: "calc(100% - 40px)" }}>{item.tel}</Paper>
                    </div>
                  </div>
                  <h3 style={{margin: 0}}>문의 내용</h3>
                  <div className="group">
                    <label style={{ minWidth: 130 }}>문의 내용:</label>
                    <Paper elevation={0} variant="outlined" style={{ padding: 10, width: "calc(100% - 20px)", height: 250 }}>{item.content}</Paper>
                  </div>
                  <div className="group">
                    <label style={{ minWidth: 130 }}>첨부 파일</label>
                    <Paper elevation={0} variant="outlined" style={{ padding: 10, width: "calc(100% - 20px)" }}><a href={item.file_path}>{item.file_name}</a></Paper>
                  </div>
                </DialogContentText>
              ))}
            </DialogContent>
          </div>
        </Dialog>
      ) : null}
    </div>
  )
}