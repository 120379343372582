// 고객센터 관리 페이지
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  InputBase,
  IconButton,
  Tab,
  Box,
} from "@material-ui/core";
import {TabList, TabPanel, TabContext} from '@material-ui/lab';
import { Search as SearchIcon } from "@material-ui/icons";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import styled from 'styled-components';
import axios from 'axios';
import HelpCenterTable from "./components/HelpCenterTable";
import DirectCenterTable from "./components/DIrectCenterTable";
import AnnounceTable from "./components/AnnounceTable";

function changeDirectData(array, index1){
  array.forEach((item) => {
    const value1 = item[index1];
    item[index1] = value1.split('T')[0];
  })
  return array;
}

function changeHelpData(array, index1, index2, index3){
  array.forEach((item) => {
    const value1 = item[index1];
    const value2 = item[index2];
    const value3 = item[index3];
    item[index1] = value1.split('T')[0];
    if(value2 == null){
      item[index2] = '';
    } else {
      item[index2] = value2.split('T')[0];
    }
    if(value3 == 0){
      item[index3] = '비공개';
    } else {
      item[index3] = '공개';
    }
  })
  return array;
}

export default function MemberCenter(){
  const [directTableList, setDirectTableList] = useState([]);
  const [helpTableList, setHelpTableList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const row1 = ["NO","공개 여부","카테고리","제목","등록일","수정일","조회수", "작업"]
  const row2 = ["NO","제목","문의자명","문의자 소속","문의일","TD_담당자", "상세보기"]
  const searchTargets = ["title", "is_published"];
  //tab
  const [value, setValue] = useState("1");
  const tabChange = (event, newValue) => {
    setValue(newValue);
  };

  // search
  const getValue = (e) => {
    setSearchInput(e.target.value)
  }

  //1:1 고객센터 api
  useEffect(() => {
    axios.get("/api/memberCenter/directList")
      .then((response) => {
        setDirectTableList(changeDirectData([...response.data.data], "request_date").reverse())
        // console.log([response.data.data].reverse())
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  // 공지사항, FAQ api
  useEffect(() => {
    axios.get("/api/memberCenter/helpList")
      .then((response) => {
        setHelpTableList(changeHelpData([...response.data.data], "date_created", "date_modified", "is_published").reverse())
        // console.log(response.data.data)
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);


  return(
    <div>
      <PageTitle title="고객 센터" />
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', '& .PrivateTabIndicator-root-62.PrivateTabIndicator-colorSecondary-64.MuiTabs-indicator': { backgroundColor:'#6BB9FF' } }} style={{paddingLeft: '24px'}}>
            <TabList onChange={tabChange} aria-lable="고객센터">
              <Tab label="공지사항" value="1" />
              <Tab label="FAQ" value="2" />
              <Tab label="1:1 문의" value="3" />
            </TabList>
          </Box>
          <Grid style={{paddingLeft: '24px'}}>
            <SearchPaper component="form" sx={{ display: 'flex', alignItems: 'center', width: '400' }}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" ><SearchIcon /></IconButton>
              <SearchInput
                placeholder="Search"
                inputProps={{'aria-label': 'Search'}}
                onChange = {getValue}
              />
            </SearchPaper>
          </Grid>
          {/*공지사항*/}
          <TabPanel value="1" label="공지사항">
            <Grid item xs={12} >
              <Widget>
                <AnnounceTable row={row1} filterKeyword={searchInput} totalData={helpTableList.filter(item => item.kind_code === 2)} searchTargets={searchTargets}/>
              </Widget>
            </Grid>
          </TabPanel>
          {/*FAQ*/}
          <TabPanel value="2" label="FAQ">
            <Grid item xs={12} >
              <Widget>
                <HelpCenterTable row={row1} filterKeyword={searchInput} totalData={helpTableList.filter(item => item.kind_code === 3)} searchTargets={searchTargets}/>
              </Widget>
            </Grid>
          </TabPanel>
          {/*1:1*/}
          <TabPanel value="3" label="1:1 문의">
            <Grid item xs={12} >
              <Widget>
                <DirectCenterTable row={row2} filterKeyword={searchInput} totalData={directTableList} searchTargets={searchTargets}/>
              </Widget>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

const SearchInput = styled (InputBase)`
    display: inline-flex;
    margin-top: 10px;
    width: 80%;
`
const SearchPaper = styled(Paper)`
    margin-bottom: 10px;
    width: 20%;
`