import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Pagination from "react-js-pagination";
import "../../../components/Style/Table.css"
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter
} from "@material-ui/core";

export default function DirectTable(props){
  const row = props.row;
  const totalData = props.totalData;
  const [dataCnt, setDataCnt] = useState(0);

  // pagination
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState([]);
  const postPerPage: number=5;
  const indexOfLastPost = page*postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  useEffect(()=>{
    setCurrentPost(totalData.slice(indexOfFirstPost, indexOfLastPost))
    if(totalData.length === 0){
      setDataCnt(1)
    } else{ setDataCnt(totalData.length) }
  },[totalData, page]);

  return(
    <div><TableContainer>
      <Table className="mb-0">
        <TableHead>
          <TableRow>
            {row && row.map(headerItem => (
              <TableCell style={{ textAlign: 'center'}}>{headerItem}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPost && currentPost.map(item => (
            <TableRow key={item.num}>
              <TableCell style={{ textAlign: 'center'}}>{item.title}</TableCell>
              <TableCell style={{ textAlign: 'center'}}>{item.member_name}</TableCell>
              <TableCell style={{ textAlign: 'center'}}>{item.org_name}</TableCell>
              <TableCell style={{ textAlign: 'center'}}>{item.request_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <RealTableFooter>
        <Pagination
          activePage={page}
          itemsCountPerPage={postPerPage}
          totalItemsCount={dataCnt}
          pageRangeDisplayed={5}
          prevPageText={"<"}
          nextPageText={">"}
          onChange={handlePageChange}
        />
      </RealTableFooter>
    </TableContainer></div>
  )
}

const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`