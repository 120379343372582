import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

function changeGradeList(arr, index1, index2){
  arr.forEach((item) => {
    const value1 = item[index1];
    const value2 = item[index2];
    if(value1 == null){
      item[index1] = 0;
    }
    if(value2 == null){
      item[index2] = 0;
    }
  })
  // console.log(arr)
  return arr;
}

export default function GradePopUp(props){
  const { open, close, member_seq } = props;
  // console.log(member_seq)
  const [couponList, setCouponList] = useState('');
  const [coupon_code, setCoupon_code] = useState('');
  const [checkSub, setCheckSub] = useState('');
  const classes = useStyles();
  const getValue = (e) => {
    setCoupon_code(e.target.value)
    // console.log(coupon_code)
  }

  //쿠폰 정보 보기
  useEffect(() => {
    // console.log(member_seq)
    axios.get("/api/member/checkSubscription?member_seq=" + member_seq)
      .then((response)=>{
        // console.log(response);
        setCheckSub(changeGradeList([response.data.data], "total_project_cnt", "patents_project_cnt")[0]);
        // console.log(checkSub);
      })
      .catch((error) => {
        console.error('Error gradePopUp checkSubscription axios request:', error);
      })
  }, [member_seq]);

  //쿠폰 리스트 api
  useEffect(() => {
    axios.get("/api/member/couponList")
      .then((response)=>{
        setCouponList(response.data.data);
      })
      .catch((error) => {
        console.error('Error gradePopUp checkSubscription axios request:', error);
      })
  }, [member_seq]);

  //쿠폰 등록 api
  const handleSubmit = () => {
    axios.get("/api/member/gradeDetail?coupon_code=" + coupon_code + "&member_seq=" + member_seq)
      .then(()=>{
      })
      .catch((error) => {
        console.error('Error gradePopUp gradeDetail axios request:', error);
      })
  }

  //쿠폰 삭제 api
  const handleDelete = () => {
    axios.get("/api/member/gradeDelete?member_seq=" + member_seq)
      .then(()=>{
      })
      .catch((error) => {
        console.error('Error gradePopUp gradeDelete axios request:', error);
      })
  }

  return(
      <div className={open ? "gradeClickOpen modal" : "modal"}>
      {open ? (
        <Dialog
          open={open}
          onClose={close}
          fullWidth
          maxWidth="xs"
          PaperProps={{
            sx: {
              boxShadow: "none",
            },
          }}
        >
          <DialogTitle style={{marginTop: 20}}><Typography variant="h5" align="center">등급 쿠폰 관리</Typography></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ justifyContent: 'center', display: 'flex'}}>
                  {checkSub && (
                    <ul>
                      <li>등급: {checkSub.grade}</li>
                      <li>시작 일자: {checkSub.start_date}</li>
                      <li>남은 일수: {checkSub.remind_date}</li>
                      <li>통합 프로젝트 수: {checkSub.total_project_cnt}/{checkSub.total_project_limit}</li>
                      <li>특허 프로젝트 수: {checkSub.patents_project_cnt}/{checkSub.patents_project_limit}</li>
                    </ul>
                    )}
              </Box>
              <Box
                component="form"
                sx={{ justifyContent: 'center', display: 'flex',
                  '& > :not(style)': { m: 1, width: '20ch' },
                }}
                noValidate
                autoComplete="off">
                <FormControl variant="standard" >
                  <InputLabel>등록할 쿠폰</InputLabel>
                  <Select label="couponCode" onChange={getValue}>
                    {couponList && couponList.map(item => (
                      <MenuItem value={item.grant_code}>{item.grant_code}<br /><sub>:{item.note}</sub></MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: classes.MuiDialogActions }} style={{ marginBottom: 30}}>
            <Button style={{backgroundColor:"#EA6A6A", color: "white", fontWeight: 'medium', boxShadow: 'none'}} variant="contained" onClick={handleDelete}>쿠폰 해제</Button>
            <Button style={{backgroundColor:"#3085D6", color: "white", fontWeight: 'medium', boxShadow: 'none'}} variant="contained" onClick={handleSubmit}>쿠폰 등록</Button>
          </DialogActions>
        </Dialog>
      ) : null}
      </div>
  );
}
const useStyles = makeStyles(theme => ({
  MuiDialogActions: {
    justifyContent: 'center'
  }
}))