import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Pagination from "react-js-pagination";
import "../../../components/Style/Table.css"
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter, Button,
} from "@material-ui/core";
import axios from "axios";

export default function WaitAppliTable(props){
  const filterKeyword = props.filterKeyword || '';
  const row = props.row;
  const searchTargets = props.searchTargets;
  const totalData = props.totalData;
  const [dataCnt, setDataCnt] = useState(0);
  const [buttonHidden, setButtonHidden] = useState([]);

  // pagination
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState([]);
  const postPerPage: number=10;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  //출원인 승인 버튼
  const changeAssigneeHistory = (item) => {
    axios.get("/api/applicant/changeAssigneeHistory?_seq=" + item.seq
      + "&member_seq=" + item.member_seq
      + "&change_flag=" + 1
      + (item.request_member ? "&request_member=" + item.request_member : "")
      + (item.applicant_original ? "&original_assignee=" + item.applicant_original : "")
      + (item.applicant_ko ? "&assignee_ko=" + item.applicant_ko : "")
      + (item.applicant_en ? "&assignee_en=" + item.applicant_en : "")
      + (item.country_code ? "&country=" + item.country_code : ""))
      .then(()=>{
        item.change = "승인";
        setButtonHidden((prevHiddenButtons) => [...prevHiddenButtons, item.seq]);
        item.change_flag = 1;
        return item;
      })
      .catch((error) => {
        console.error('Error changeAssigneeHistory axios request:', error);
      })
  }

  // 출원인 거절 버튼
  const noChangeAssigneeHistory = (item) => {
    axios.get("/api/applicant/changeAssigneeHistory?_seq=" + item.seq
      + "&member_seq=" + item.member_seq
      + "&change_flag=" + 2
      + (item.request_member ? "&request_member=" + item.request_member : "")
      + (item.applicant_original ? "&original_assignee=" + item.applicant_original : "")
      + (item.applicant_ko ? "&assignee_ko=" + item.applicant_ko : "")
      + (item.applicant_en ? "&assignee_en=" + item.applicant_en : "")
      + (item.country_code ? "&country=" + item.country_code : ""))
      .then(()=>{
        item.change = "거절";
        item.change_flag = 2;
        setButtonHidden((prevHiddenButtons) => [...prevHiddenButtons, item.seq]);
        return item;
      })
      .catch((error) => {
        console.error('Error changeAssigneeHistory axios request:', error);
      })
  }

  const searchItem = (filterKeyword) => {
    if (filterKeyword !== ''){
      const filteredData = totalData.filter((item) => {
        return searchTargets.some((target) => {
          if (item[target] && typeof item[target] === 'string') {
            return item[target].toLowerCase().includes(filterKeyword.toLowerCase());
          }
        })
      });
      // pagination filter data slice
      setCurrentPost(filteredData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setDataCnt(filteredData.length);
    } else {
      // pagination total data slice
      setCurrentPost(totalData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setDataCnt(totalData.length);
    }
  }

  useEffect(() => {
    searchItem(filterKeyword)
    // console.log('aaa')
  }, [totalData, page, filterKeyword, buttonHidden])

  return(
    <div>
      <TableContainer>
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              {row && row.map(headerItem => (
                <TableCell style={{ textAlign: 'center'}}>{headerItem}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPost && currentPost.map(item => (
              <TableRow key={item.num}>
                <TableCell style={{ textAlign: 'center'}}>{item.seq}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.member_name}<br />{item.member_email}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.created_time}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.last_modified_time}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.applicant_original}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.applicant_ko}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.applicant_en}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.country_code}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>
                  <Button style={{color: "black", fontWeight: 'medium', boxShadow: 'none', display: (buttonHidden.includes(item.seq) ? "none" : "block")}} onClick={() => changeAssigneeHistory(item)}>승인</Button>
                  <Button style={{color: "black", fontWeight: 'medium', boxShadow: 'none', display: (buttonHidden.includes(item.seq) ? "none" : "block")}} onClick={() => noChangeAssigneeHistory(item)}>거부</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <RealTableFooter>
          <Pagination
            activePage={page}
            itemsCountPerPage={postPerPage}
            totalItemsCount={dataCnt}
            pageRangeDisplayed={5}
            prevPageText={"<"}
            nextPageText={">"}
            onChange={handlePageChange}
          />
        </RealTableFooter>
      </TableContainer>
    </div>
  )
}

const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`