import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Pagination from "react-js-pagination";
import "../../../components/Style/Table.css"
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter, Button,
} from "@material-ui/core";
import axios from "axios";

export default function AnnounceTable(props){
  const filterKeyword = props.filterKeyword || '';
  const row = props.row;
  const searchTargets = props.searchTargets;
  const totalData = props.totalData;
  const [dataCnt, setDataCnt] = useState(0);
  const [seq, setSeq] = useState();

  // pagination
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState([]);
  const postPerPage: number=10;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  //공지사항 삭제
  const deleteFAQ = (item) => {
    setSeq(item.bbs_help_seq);
    axios.get("/api/memberCenter/deleteHelp?seq=" + seq)
      .then((response) => {
      })
      .catch(function(error){
        console.log(error)
      });
  }

  const searchItem = (filterKeyword) => {
    if (filterKeyword !== ''){
      const filteredData = totalData.filter((item) => {
        return searchTargets.some((target) => {
          if (item[target] && typeof item[target] === 'string') {
            return item[target].toLowerCase().includes(filterKeyword.toLowerCase());
          }
        })
      });
      // pagination filter data slice
      setCurrentPost(filteredData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setDataCnt(filteredData.length);
    } else {
      // pagination total data slice
      setCurrentPost(totalData.slice(page*postPerPage-postPerPage, page*postPerPage));
      setDataCnt(totalData.length);
    }
  }

  useEffect(() => {
    searchItem(filterKeyword)
  }, [totalData, page, filterKeyword])

  return(
    <div>
      <TableContainer>
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              {row && row.map(headerItem => (
                <TableCell style={{ textAlign: 'center'}}>{headerItem}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPost && currentPost.map(item => (
              <TableRow key={item.num}>
                <TableCell style={{ textAlign: 'center'}}>{item.bbs_help_seq}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.is_published}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.category}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.title}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.date_created}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.date_modified}</TableCell>
                <TableCell style={{ textAlign: 'center'}}>{item.count_view}</TableCell>
                <TableCell style={{ textAlign: 'center'}}><Button>수정</Button><Button onClick={() => deleteFAQ(item)}>삭제</Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <RealTableFooter>
          <Pagination
            activePage={page}
            itemsCountPerPage={postPerPage}
            totalItemsCount={dataCnt}
            pageRangeDisplayed={5}
            prevPageText={"<"}
            nextPageText={">"}
            onChange={handlePageChange}
          />
        </RealTableFooter>
      </TableContainer>
    </div>
  )
}

const RealTableFooter = styled (TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`