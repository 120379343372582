// 출원인 정비 페이지
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  InputBase,
  IconButton,
  Tab,
  Box,
} from "@material-ui/core";
import {TabList, TabPanel, TabContext} from '@material-ui/lab';
import { Search as SearchIcon } from "@material-ui/icons";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import styled from 'styled-components';
import axios from 'axios';
import AppliTable from "./components/AppliTable";
import WaitAppliTable from "./components/WaitAppliTable";

function changeApplicantData(array, index1, index2, index3){
  array.forEach((item) => {
    const value1 = item[index1];
    const value2 = item[index2];
    const value3 = item[index3];
    item[index1] = value1.split('T')[0];
    item[index2] = value2.split('T')[0];
    if (value3 == 1){
      item.change = "승인"
    } else if (value3 == 2){
      item.change = "거부"
    } else { item.change = "대기"}
  })
  // console.log(array);
  return array;
}

export default function Applicant(){
  const [tableList, setTableList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const row1 = ["NO","제안인","등록일","수정일","출원인_원본", "출원인_KO","출원인_EN","국적", "승인 여부"]
  const row2 = ["NO","제안인","등록일","수정일","출원인_원본", "출원인_KO","출원인_EN","국적","변경한 사람", "승인 여부"]
  const searchTargets = ["member_name", "applicant_en", "applicant_ko"];
  const [value, setValue] = useState('1');
  // search
  const getValue = (e) => {
    setSearchInput(e.target.value)
  }
  const tabChange = (event, newValue) => {
    setValue(newValue);
  };

  //api
  useEffect(() => {
    axios.get("/api/applicant/assigneeHistory?search_type=TOTAL")
      .then((response) => {
        setTableList(changeApplicantData([...response.data.data],"created_time","last_modified_time", "change_flag").reverse())
        // console.log(tableList)
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);
  return(
    <div>
      <PageTitle title="출원인 정비" />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', '& .PrivateTabIndicator-root-61 PrivateTabIndicator-colorSecondary-63 MuiTabs-indicator': { backgroundColor:'#6BB9FF' } }} style={{paddingLeft: '24px'}}>
          <TabList onChange={tabChange} aria-lable="프로젝트 관리">
            <Tab label="대기 출원인" value="1" />
            <Tab label="승인된 출원인" value="2" />
            <Tab label="거절된 출원인" value="3" />
          </TabList>
        </Box>
        <Grid style={{paddingLeft: '24px'}}>
          <SearchPaper component="form" sx={{ display: 'flex', alignItems: 'center', width: '400' }}>
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" ><SearchIcon /></IconButton>
            <SearchInput
              placeholder="Search"
              inputProps={{'aria-label': 'Search'}}
              onChange = {getValue}
            />
          </SearchPaper>
        </Grid>
        {/*대기 출원인*/}
        <TabPanel value="1">
          <Grid item xs={12} >
            <Widget>
              <WaitAppliTable row={row1} filterKeyword={searchInput} totalData={tableList.filter(item => item.change === "대기")} searchTargets={searchTargets}/>
            </Widget>
          </Grid>
        </TabPanel>
        {/*승인 출원인*/}
        <TabPanel value="2">
          <Grid item xs={12} >
            <Widget>
              <AppliTable row={row2} filterKeyword={searchInput} totalData={tableList.filter(item => item.change === "승인")} searchTargets={searchTargets}/>
            </Widget>
          </Grid>
        </TabPanel>
        {/*거절 출원인*/}
        <TabPanel value="3">
          <Grid item xs={12} >
            <Widget>
              <AppliTable row={row2} filterKeyword={searchInput} totalData={tableList.filter(item => item.change === "거부")} searchTargets={searchTargets}/>
            </Widget>
          </Grid>
        </TabPanel>
      </TabContext>
    </div>
  );
}

const SearchInput = styled (InputBase)`
    display: inline-flex;
    margin-top: 10px;
    width: 80%;
`
const SearchPaper = styled(Paper)`
    margin-bottom: 10px;
    width: 20%;
`